<template>
  <div>
    <div class="perInfo A4Container flexCloumnCenter">
      <div class="form">
        <div class="table" style="height: 100%;overflow:hidden;">
          <el-row v-if="scoreList && scoreList.length > 0" class="flexStretch" style="">
            <el-col :span="2" class="cell flexCenter" style="border-left: 0;font-weight: 700;">
              学<br />科<br />成<br />绩
            </el-col>
            <el-col :span="22">
              <div style="width: 100%;">
                <el-row v-for="(list, count) in scoreList" :key="count">
                  <el-col :span="24">
                    <div style="width: 100%;">
                      <el-row>
                        <el-col :span="3" class="cell flexCenter" style="height: 50px;">科<br />目</el-col>
                        <el-col :span="3" class="cell cellMore flexCenter" v-for="(item, index) in list" :key="index"
                          style="height: 50px!important;overflow-y: hidden;font-size: 12px!important;padding: 3px;text-align: center;">
                          {{ item.subject|| '' }}
                        </el-col>
                      </el-row>
                      <el-row>
                        <el-col :span="3" class="cell flexCenter" style="height: 50px;">成<br />绩</el-col>
                        <el-col :span="3" class="cell cell50 flexCenter" v-for="(item, index) in list" :key="index">
                          {{ item.score ? item.score : '' }}
                        </el-col>
                      </el-row>
                    </div>
                  </el-col>
                </el-row>
              </div>
            </el-col>
          </el-row>

          <el-row class="" style="height: calc( ( 100% - 550px ) / 2 )">
            <el-col :span="2" class="cell flexCenter" style="height: 100%;border-left: 0;font-weight: 700;">
              专<br />业<br />技<br />能
            </el-col>
            <el-col :span="22" class="cell flex_skill" style="height: 100%;padding: 10px;display: inline-block;">
              <div v-for='( item, index) in formData.skill_json'
                style='width: 100%;display: inline-block;height: 20px;line-height: initial;'>
                <div style='width: 100%;'>{{ index + 1 }}. {{ item }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row class="" style="height: calc( ( 100% - 550px ) / 2 );">
            <el-col :span="2" class="cell flexCenter" style="height: 100%;border-left: 0;font-weight: 700;">
              专<br />业<br />证<br />书
            </el-col>
            <el-col :span='22' class='cell flexStart' style='height: 100%;padding: 10px;display: inline-block;'>
              <div v-for='( item, index) in formData.skill_img'
                style='width: 100%;display: inline-block;height: 20px;line-height: initial;'>
                <div style='width: 100%;'>{{ index + 1 }}. {{ item.name || item.fileName || '-' }}</div>
              </div>
            </el-col>
          </el-row>

          <el-row class='' style="height: 100px;">
            <el-col :span="2" class="cell flexCenter" style="height: 100%;border-left: 0;font-weight: 700;">
              特<br />长
            </el-col>
            <el-col :span="22" class="cell" style="height: 100%;padding: 10px;">{{ formData.specialty }}</el-col>
          </el-row>

          <el-row class='' style="height: 50px;">
            <el-col :span="2" class="cell flexCenter" style="height: 50px;border-left: 0;font-weight: 700;">健康<br />状况</el-col>
            <el-col :span="4" class="cell cell50 flexCenter">{{ formData.health }}</el-col>
            <el-col :span="4" class="cell cell50 flexCenter">身高</el-col>
            <el-col :span="5" class="cell cell50 flexAround">
              <div>{{ formData.height }}</div>
              <div>CM</div>
            </el-col>
            <el-col :span="4" class="cell cell50 flexCenter">体重</el-col>
            <el-col :span="5" class="cell cell50 flexAround">
              <div>{{ formData.weight }}</div>
              <div>KG</div>
            </el-col>
          </el-row>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {

  props: {
    stuinfo: {
      type: Object,
      default: () => {
        return {}
      }
    },
    kemu: {
      type: Array,
      default: () => {
        let s = new Array(7)
        let f = new Array(4)
        f.map(a => {
          a = s
        })
        return f
      }
    }
  },
  data() {
    return {
      isShowModal: true,
      formData: {},
      scoreList: [],
    }
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      let _this = this
      let info = JSON.parse(JSON.stringify(_this.stuinfo))
      _this.formData = info
      let score = JSON.parse(JSON.stringify(_this.kemu))
      _this.scoreList = score
      // console.log(118,score)
      if (info.stu_name) {

      } else {
        setTimeout(function () {
          _this.init()
        }, 400)
      }
    },
  }
}
</script>

<style lang="less" type="text/less">
.flex_skill {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
}
.perInfo .el-col {
  display: flex;
  padding: 0;
  margin: 0;
}

.perInfo .el-row {
  width: 100%;
  padding: 0;
  margin: 0;
}
</style>
<style scoped="scoped" type="text/less" lang="less">
.flexStretch {
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
}

.perInfo {
  font-size: 20px;
  box-sizing: border-box;
  margin: 0 auto;
  padding: 25px 0;

  .title {
    font-size: 20px;
    line-height: 50px;
  }

  .form {
    width: calc(100% - 50px);
    height: calc(100% - 0px);
    border: 1px solid #000;
    border-top: 0;

    div {
      font-size: 14px;
      color: #323232;
      box-sizing: border-box;
    }

    .content {
      width: 100%;
      height: 100%;
      padding: 10px;
      white-space: break-spaces;
      word-break: break-all;
      overflow: hidden;
    }

    .el-row {
      //border-top: 1px solid #000;
      //border-bottom: 1px solid #000;
    }
  }

  .col-row {
    width: 100%;
    margin: 0;
    padding: 0;
  }
}


.perInfo {

  .table {
    width: 100%;
  }

  .borderR {
    border-right: 1px solid #333;
  }

  .borderB {
    border-bottom: 1px solid #333;
  }

  .FS18 {
    font-size: 18px;
  }

  .bold {
    font-weight: 700;
    font-family: Avenir, Helvetica, Arial, sans-serif;
  }

  .cell {
    border-left: 1px solid #333;
    border-top: 1px solid #333;

    min-height: 30px;
    //line-height: 30px;
    //text-align: center;
    white-space: nowrap;
    font-size: 14px;
    font-family: "宋体";
    word-break: break-all;
    white-space: normal;
  }

  .cell50 {
    min-height: 50px;
    line-height: 50px;
    font-size: 14px;
    font-family: "宋体";
  }

  .cellMore {
    border-left: 1px solid #333;
    border-top: 1px solid #333;
    min-height: 50px;
    text-align: left;
    font-size: 14px;
    font-family: "宋体";
  }
}

.uline {
  text-decoration: underline;
}
</style>
