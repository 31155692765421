<template>
    <div>
        <div class="pdfok" id="pdfok" v-if="pdfok"></div>

        <div id="printMe" style=" width: 21cm; margin: 0 auto;">
            <Zijian_01 :stuinfo="stuInfo"></Zijian_01>
            <Zijian_02 :stuinfo="stuInfo"></Zijian_02>
            <Zijian_03 :stuinfo="stuInfo" :kemu="kemu"></Zijian_03>
            <div class='' v-for='(item, index) in stuInfo.imgs'>
                <Zijian_04 :stuinfo="item"></Zijian_04>
            </div>
        </div>


        <el-button v-if="false" v-print="'#printMe'" type="primary" style="position: fixed;top: 0;left: 0;z-index: 999;">打印
        </el-button>

    </div>
</template>

<script>
import Zijian_01 from './com_zijian/cover'
import Zijian_02 from './com_zijian/perInfo'
import Zijian_03 from './com_zijian/zijian_03'
import Zijian_04 from './com_zijian/zijian_04'

export default {
    name: 'exportZijian',
    components: {
        Zijian_01,
        Zijian_02,
        Zijian_03,
        Zijian_04
    },
    data() {
        return {
            pdfok: false,
            printObj: {
                id: 'printMe',
                popTitle: '',
                extraHead: '<meta http-equiv="Content-Language"content="zh-cn"/>'
            },
            userid: '',
            cover: {},

            stuInfo: {},
            yuebao: [{}],
            zhoubao: [{}],
            kemu: [],
            arrHealth: [{ dictValue: 1, dictLabel: '健康' }, { dictValue: 2, dictLabel: '良好' }, { dictValue: 3, dictLabel: '较差' }],
        }
    },
    mounted() {
        let stu_id = this.getParams('id')
        let pmid = this.getParams("pmid");
        this.getData(stu_id, pmid)
        this.getKemu(stu_id, pmid)
    },
    methods: {
        //过滤特殊字符, 校验所有输入域是否含有特殊符号
        stripscript(s) {
            var mdash = '&mdash;'
            var nbsp = '&nbsp;'
            var quot = '&quot;'
            var ldquo = '&ldquo;'
            var rdquo = '&rdquo;'
            var amp = '&amp;'
            var lt = '&lt;'
            var gt = '&gt;'


            var rs = ''
            rs = s.replace(new RegExp(mdash, 'g'), '-');
            rs = rs.replace(new RegExp(nbsp, 'g'), '');
            rs = rs.replace(new RegExp(quot, 'g'), '"');
            rs = rs.replace(new RegExp(ldquo, 'g'), '“');
            rs = rs.replace(new RegExp(rdquo, 'g'), '”');
            rs = rs.replace(new RegExp(amp, 'g'), '&');
            rs = rs.replace(new RegExp(lt, 'g'), '<');
            rs = rs.replace(new RegExp(gt, 'g'), '>');
            return rs
        },
        timeAdd0(n) {
            n = n.toString();
            return n[1] ? n : '0' + n
        },

        formatshijianchuo(shijianchuo) {
            //shijianchuo是整数，否则要parseInt转换
            var time = new Date(shijianchuo)
            var y = time.getFullYear()
            var m = time.getMonth() + 1
            var d = time.getDate()
            var h = time.getHours()
            var mm = time.getMinutes()
            var s = time.getSeconds()
            return y + '-' + this.timeAdd0(m) + '-' + this.timeAdd0(d)
        },
        getData(stu_id, pmid) {
            let _this = this
            this.$http.post('/api/init_zijian_pdf', { stu_id: stu_id, pmid: pmid }).then(res => {
                let info = JSON.parse(JSON.stringify(res.data))
                info.summary = info.summary ? this.stripscript(info.summary) : ''
                if (info.remark) {
                    info.remark = this.stripscript(info.remark)
                } else if (info.sp_status==1) {
                    info.remark = info.sp_word
                }
                info.remark = info.remark ? this.stripscript(info.remark) : ''
                info.specialty = info.specialty ? this.stripscript(info.specialty) : ''

              
                if (info.awards) {
                    info.awards = this.stripscript(info.awards)
                    if (info.awards.indexOf('&quot;') > -1) {
                        info.awards = info.awards.replace(/&quot;/g, '"')
                    }
                    if (info.awards && this.isJSON(info.awards)) {
                        info.awards = JSON.parse(info.awards)
                    } else {
                        info.awards = [info.awards]
                    }
                }

                if (info.skill_json && this.isJSON(info.skill_json)) {
                    info.skill_json = JSON.parse(info.skill_json)
                }
                if (info.skill_img && this.isJSON(info.skill_img)) {
                    let imgsArr = []
                    try {
                        info.skill_img = JSON.parse(info.skill_img)
                        info.skill_img.map(a => {
                            if (a.file && a.file != '') {
                                a.file += `?x-oss-process=image/resize,w_600`
                                imgsArr.push(a)
                            }
                        })
                        let imgs = JSON.parse(JSON.stringify(imgsArr))
                        console.log(154, imgs)
                        if (imgs && imgs.length > 0) {
                            info.imgs = this.util_spliceArrByN(imgs, 2)
                        }
                    } catch {
                        info.skill_img = {}
                    }
                }
                this.stuInfo = info
                this.$nextTick(() => {
                    setTimeout(() => { _this.pdfok = true }, 500)
                })

            })
        },
        getKemu(stu_id, pmid) {
            this.$http.post('/api/stu_examscore_list_public', {
                stu_id: stu_id, pmid: pmid
            }).then(res => {
                if (res && res.data && res.data.length > 0) {
                    this.kemu = this.handleGroup(res.data)
                } else {
                    this.kemu = this.handleGroup([])
                }
            }).catch(_ => {
                this.kemu = this.handleGroup([])
            })
        },
        handleGroup(kemu) {
            if (!kemu || kemu.length == 0) {
                kemu = new Array(28).fill({})
            } else if (kemu.length < 28) {
                let difflen = 28 - (kemu.length)
                kemu = this._lo.concat(kemu, new Array(difflen).fill({}))

            }
            let kemuGroup = this._lo.chunk(kemu, 7)
            let kemuResult = kemuGroup.splice(0, 4)
            return kemuResult
        },

        getParams(name) {
            let n_url = window.location.href
            n_url = n_url.replace('/#', '')
            if (n_url.indexOf('?') > 0) {
                n_url = n_url.split('?')[1]
            }
            var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)', 'i')
            var r = n_url.match(reg)
            if (r != null) return unescape(r[2])
            return null
        },
        //分割数组 arr：原数组  num：分割后每个数组里的数据条数
        util_spliceArrByN(arr, num) {
            var _arr = []
            while (arr.length) {
                _arr.push(arr.splice(0, num))
            }
            return _arr
        },
        //数组按某属性分组
        groupBy(array, f) {
            let groups = {}
            array.forEach(function (o) {
                let group = JSON.stringify(f(o))
                groups[group] = groups[group] || []
                groups[group].push(o)
            })
            return Object.keys(groups).map(function (group) {
                return groups[group]
            })
        }
    }
}
</script>
<style lang="less" type="text/less">
@import "/public/css/pdf_default";
</style>